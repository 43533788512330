import Cookies from 'js-cookie';
import { element } from 'prop-types';

window.addEventListener('DOMContentLoaded', (event) => {
  console.log('DOM fully loaded and parsed');
  initLikes();
});

const initLikes = () => {
  addLikeClickListeners();
  if (needToUpdate()) {
   console.log('Надо обновить лайки из API');
    fetchUserLikes()
      .then(() => markLikeableElementsOnPage())
      .catch(e => console.log(e));
  } 
  else {
   console.log('Не надо получать лайки по API');
    markLikeableElementsOnPage();
  }
};

// Надо ли заново сходить в API и получить данные о лайках ?
// Проверка авторизован пользователь или нет делается по наличию 
// в cookies ключа _edimdoma_userId, если ключа нет, то 
// значит удалим текущие значения в сторадже и скажм, что 
// получать через API их не надо.
// Если у нас прошло больше дня, как мы через API лайки обновляли, то 
// Обновим их еще раз
const needToUpdate = () => {
  const userId = Cookies.get("_edimdoma_userId");
  if(userId === undefined || userId === null){
    localStorage.removeItem('likesUpdatedAt');
    localStorage.removeItem('likesData');
    return false;
  }

  let needToUpdate = false;
  const likesUpdatedAt = localStorage.getItem('likesUpdatedAt');

  if ( likesUpdatedAt ) {
    const updateFreqencyInDays = 1;
    const secondInDay = 86400;
    const updateFreqencyInSeconds = updateFreqencyInDays * secondInDay;
    const currentDate = new Date();
    const lastUpdatedDate = new Date(likesUpdatedAt);
    const secondsPassed = (currentDate.getTime() - lastUpdatedDate.getTime()) / 1000;
    if (secondsPassed > updateFreqencyInSeconds) { needToUpdate = true };
  }
  else {
    needToUpdate = true
  };
  console.log(`likesUpdatedAt: ${likesUpdatedAt} | userId: ${userId}`)

  return needToUpdate;
};

// На основании того, что есть в localStorage отметим сердечками
const markLikeableElementsOnPage = () => {
  const userId = Cookies.get("_edimdoma_userId");
  if (userId === undefined || userId === null) { 
   console.log("Пользователь не авторизован, значит не надо отмечать, что он лайкал");
    return; 
  };

  console.log("Найдем объекты для лайков и отметим их");
  const likeableElements = document.querySelectorAll('.ajax_like_data');
  const likes = getLikes();
  if (!likes) { return; }
  
  likeableElements.forEach(elem => {
    const elementType = elem.dataset.objectType;
    const elementId = elem.dataset.objectId;
    const liked = checkLike(likes, elementType, elementId);
    //console.log("Element: ",elem, elementType, elementId, liked);
    if (liked) {
      const likeBlockElem = elem.parentElement;
      
      // Не важно, где кнопка лайка, всегда для самого 
      // Элемента так будет лайк отмечаться.
      elem.classList.toggle("fonticon_heart");
      elem.classList.toggle("fonticon_like");
      if (likeBlockElem.classList.contains('button_like-it')) {
        // Если это кнопка лайка в просмотре
        likeBlockElem.classList.remove("button_pink");
        likeBlockElem.classList.toggle("button_red");
      }
      else {
        // Если кнопка лайка в списке
        likeBlockElem.classList.toggle("liked");
      };
    };
  });
};

// Проверяет, что такой элемент есть в лайках
const checkLike = (likes, type, id) => {
  const currentTypeLikes = likes[type];
  if (currentTypeLikes){
    return currentTypeLikes.includes(parseInt(id));
  }
  else{
    return false;
  }
};


// Отлавливаем триггер того, что на странице что-то поменялось и надо переинициализировать 
// листенеры лайков
document.addEventListener('user-timeline-loaded', () => initLikes(), false);

// Повесим отслеживание событий на клики по блоку с сердцами.
const addLikeClickListeners = () => {
  document.querySelectorAll('.ajax_like_block:not(.show_popup_auth)').forEach((elem) => {
    if(elem.classList.contains('likeListenerInited')){return}
    elem.addEventListener('click', event => {
      console.log("Засекли клик", event.target);
      const like_block = elem;
      like_block.classList.add('likeListenerInited');
      console.log("Like Block: ", like_block.classList);
      const like_btn_text = like_block.querySelector(".ajax_like_btn_text")
      const data_element = like_block.querySelector(".ajax_like_data");
      if (typeof data_element !== undefined) {
       console.log("data_element is not undefined")
        // Определяем, за что голосуем
        const objectId = data_element.dataset.objectId;
        const objectType = data_element.dataset.objectType;
        const addClass = data_element.dataset.addClass;
        const removeClass = data_element.dataset.removeClass;
        
        const data = {
          id: objectId,
          likeable_type: objectType,
        };
        if((objectId === undefined || objectId.length === 0) || (objectType === undefined || objectType.length === 0)){
          alert("ОШИБКА!\nПОЖАЛУЙСТА, ОТРПАВЬТЕ СКРИН ИЛИ ЭТОТ ТЕКСТ АДМИНИСТРАТОРУ САЙТА.\n"+"\nobjectId:"+objectId+"\nobjectType:"+objectType+ "Elem: " + elem.innerHTML + "\ndata_element: "+ data_element.outerHTML);
        }
        

        sendLike(data).then((response) => {
         console.log("Response from Likes: ", response);
          notify(response.type, response.text);
          //like_block.find("." + object_type + "_likes_count_" + object_id).html(response.likes_count);
          if (typeof response.action != "undefined") {
            if (response.action == 'like') {
              if (addClass) { 
                like_block.classList.add(addClass);
              }
              if (removeClass) { 
                like_block.classList.remove(removeClass);
              }
              data_element.classList.remove('fonticon_like');
              data_element.classList.add('fonticon_heart');
              addLikeToLocalStorage(objectType, objectId);
            }
            else if (response.action == 'unlike') {
              if(removeClass){
                like_block.classList.add(removeClass);
              }
              if(addClass){
                like_block.classList.remove(addClass);
              }
              
              data_element.classList.remove('fonticon_heart');
              data_element.classList.add('fonticon_like');
              removeLikeFromLocalStorage(objectType, objectId);
            }
          }
         console.dir(like_btn_text);
          like_btn_text.textContent = response.likes_count;

        }).catch(error => {
         console.log(error);
          notify('error', error.text);
        });
      }
      else {
        console.log("Не удалось найти элемент с данными для лайка");
      }
      
    });
  });
};


//----- Работа с localStorage

// Получим json в лайками из localStorage
const getLikes = () => {
  const likesUpdatedAt = localStorage.getItem('likesUpdatedAt');
  if (likesUpdatedAt) {
    const likesJSON = JSON.parse(localStorage.getItem('likesData'));
    return likesJSON;
  }
  else {
    return null;
  }
};

// Запоминаем json в виде строки с лайками и дату, когда это было сделано
const storeLikedData = (data) => {
  localStorage.setItem('likesUpdatedAt', new Date());
  localStorage.setItem('likesData', JSON.stringify(data));
  return true;
};

// Добавим то, что поставили лайк в localStorage
const addLikeToLocalStorage = (type, id) => {
  let likes = getLikes();
  if (likes && likes[type]) {
    likes[type].push(parseInt(id));
  }
  else {
    if (likes){
      likes[type] = [parseInt(id)];
    }
    else{
      likes = {
        [type]: [parseInt(id)]
      }
    }
    
  }
  storeLikedData(likes);
};

// Удалим из localStorage то, что поставили лайк
const removeLikeFromLocalStorage = (type, id) => {
  let likes = getLikes();
  if (likes && likes[type]) {
    const filteredAry = likes[type].filter(function(e) { return e !== parseInt(id) })
    likes[type] = filteredAry;
    storeLikedData(likes);
  }
  else{
    console.log(`Такого типа лайков ${type} небыло в localStorage`);
  }
};
// ---


//----- Работа с API 

// Отаправим запрос на лайк/дизлайн на сервер
const sendLike = (inputData) => new Promise((resolve, reject) => {
  console.log('sendLike: Отправим запрос на лайк или удаление лайка');
  const token = document.querySelector('meta[name="csrf-token"]').content;
  const httpRequest = new XMLHttpRequest();
  httpRequest.open('POST', window.location.origin + '/likes', true);
  httpRequest.setRequestHeader("Content-type", "application/json; charset=utf-8");
  // httpRequest.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
  httpRequest.setRequestHeader("X-CSRF-Token", token);
  httpRequest.setRequestHeader("X-Requested-With", 'XMLHttpRequest');
  
  httpRequest.onload = function () {
    if (this.status >= 200 && this.status < 300) {
      var response = JSON.parse(httpRequest.responseText);
     console.log("RESPONSE FROM API:", response);
      resolve(response);
    } else {
      reject(response);
    }
  };

  httpRequest.onerror = function () {
    reject({
      status: this.status,
      statusText: httpRequest.statusText
    });
  };

  const requestData = JSON.stringify({
    ...inputData, 
    authenticity_token: token
  });
  console.log("DATA: ", requestData)
  httpRequest.send(requestData);
});

// Получаем список лайков пользователя и сохраняем в localstorage
// в виде json объекта (на самом деле строка, поэтому надо будет делать
// JSON.parse для получения реально объекта json)
const fetchUserLikes = () => new Promise((resolve, reject) => {
  console.log('fetchUserLikes: Отправим запрос на обновление лайков');
   const httpRequest = new XMLHttpRequest();
   httpRequest.open('GET', window.location.origin + '/api/internal/v0/users/likes');
 
   httpRequest.onload = function () {
     if (this.status >= 200 && this.status < 300) {
       var response = JSON.parse(httpRequest.responseText);
      console.log("RESPONSE FROM API:", response);
       storeLikedData(response);
       resolve(httpRequest.responseText);
     } else {
       reject({
         status: this.status,
         statusText: httpRequest.statusText
       });
     }
   };
 
   httpRequest.onerror = function () {
     reject({
       status: this.status,
       statusText: httpRequest.statusText
     });
   };
 
   httpRequest.send();
 
 });
 // ---